import { css } from '@emotion/react';

import useModal from '@/hooks/useModal';
import { ApplicationsCount, CountChevron, ViewsCount } from '@/types/types';
import { getLabelFromValue } from '@/utils/form-data';
import useDropdownsData from '@/hooks/useDropdownsData';
import TooltipMessage from '@/components/TooltipMessage';
import { ChevronBlue } from '@/components/Icons/ChevronBlue';
import { dateFormats, formatDate } from '@/utils/daysjs-config';
import { GreenChevron } from '@/components/Icons/GreenChevron';
import CellRendererText from '@/components/Table/CellRendererText';
import { ActiveExpiredTagLabel } from '@/components/Dashboard/ActiveExpires';
import ViewApplicationModalContent from '@/components/Dashboard/ViewApplicationModalContent';
import { Tooltip } from '@mui/material';
import { red } from '@mui/material/colors';

const MIN_HOUR_SUBTEXT = `(all rates to be negotiated on a case by case basis)`;

const EMPTY_HEADER = 'No results found.';
const EMPTY_SUBHEADER = 'Reset your filters and try again.';

const ERROR = `There was an error, Please try again.`;
const TO_DELETE_MSG = 'You are about to delete ';

//

const USERS = ' user accounts. This cannot be undone.';
const USER = ' account. This cannot be undone.';

const getFormattedHourlyRate = (value: string | number) => {
  if (value) return `$${value}+/hr`;
  return '';
};

const datatableUtils = {
  TO_DELETE_MSG,
  ERROR,
  EMPTY_HEADER,
  EMPTY_SUBHEADER,
  MIN_HOUR_SUBTEXT,
  jobs: {
    TOTAL_MAX: 100,
    LIMIT_MSG: 'Locums.com has a max limit of 100 job posts per recruiter.',
    DELETE_MSG:
      'Deleting a job posting will also delete all information provided by applicants. This cannot be undone.',
    JOBS: ' job postings.',
    DELETED_PLURAL: 'Jobs have been deleted',
    DELETED_SINGULAR: 'Jobs have been deleted',
  },
  users: {
    USERS,
    USER,
    DELETED_PLURAL: 'Users have been deleted',
    DELETED_SINGULAR: 'User has been deleted',
  },
  getFormattedHourlyRate,
};

const StatusCell = ({ value }: { value: boolean }) => {
  return (
    <div
      {...{
        css: css`
          align-self: center;
        `,
      }}
    >
      <ActiveExpiredTagLabel {...{ isActive: value }} />
    </div>
  );
};

const DateCell = ({ value }: { value: string }) => {
  const formattedDate = !!value ? formatDate(value, dateFormats.mmddyyyy) : '';
  return <CellRendererText {...{ text: formattedDate || '' }} />;
};

const SpecialtyCell = ({ value }: { value: string }) => {
  const { dropdownsHashes } = useDropdownsData();
  // const label = !!value ? dropdownsHashes?.specialty?.[value]?.label : '';
  const label = getLabelFromValue(value, dropdownsHashes?.specialty);
  return <CellRendererText {...{ text: label || '' }} />;
};

const ProfessionCell = ({ value }: { value: string }) => {
  const { dropdownsHashes } = useDropdownsData();
  const label = getLabelFromValue(value, dropdownsHashes?.profession);
  return <CellRendererText {...{ text: label || '' }} />;
};

const TrendCellContainer = ({
  children,
  type,
}: {
  children: React.ReactNode;
  type: CellType;
}) => {
  return (
    <div
      {...{
        style: {
          display: 'flex',
          flexDirection: 'row',
          gap: 4,
          alignItems: 'center',
        },
        'data-tooltip-id': `trend-tooltip-${type}`,
        children,
      }}
    />
  );
};

// TYPES FOR-NOW

type CellType = 'views' | 'applications';

const TrendIconHandle = ({
  count_chevron,
  msg,
  type,
}: {
  count_chevron: CountChevron;
  msg: string;
  type: CellType;
}) => {
  const CurrentTrendIcon = Boolean(count_chevron)
    ? count_chevron === '1_blue_chevron'
      ? ChevronBlue
      : GreenChevron
    : null;

  const hasIcon = !!CurrentTrendIcon;

  return (
    <>
      {/* {hasIcon && <CurrentTrendIcon />} */}
      <Tooltip {...{ 
        title: msg, 
        hidden: !hasIcon, 
        slotProps:{...{
          tooltip: {
            sx: {
              backgroundColor: "#05051B",
              fontSize: "12px",
              fontWeight: "600"
            },
          },
        }}
        }}
        >
        <div
          {...{
            style: {
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              alignItems: 'center',
            },
          }}
        >
          {hasIcon && <CurrentTrendIcon />}
        </div>
      </Tooltip>
    </>
  );
};

const ViewsCell: React.FC<ViewsCount> = ({
  count_increase,
  count,
  count_chevron,
}) => {
  const text = String(count) || '';
  const msg = `${count_increase} new views since last logged in`;
  const type = 'views';
  return (
    <TrendCellContainer {...{ type }}>
      <CellRendererText {...{ text }} />
      <TrendIconHandle {...{ count_chevron, msg, type }} />
    </TrendCellContainer>
  );
};

const ApplicationsCell: React.FC<ApplicationsCount> = ({
  count_increase,
  count,
  count_chevron,
  job_id,
}) => {
  const text = String(count) || '';
  const msg = `${count_increase} new applications since last logged in`;

  const type = 'applications';

  const { ModalComponent, openModal, closeModal } = useModal();
  return (
    <>
      <div {...{ onClick: count === 0 ? () => {} : openModal }}>
        <TrendCellContainer {...{ type }}>
          <CellRendererText {...{ text }} />
          <TrendIconHandle {...{ count_chevron, msg, type }} />
        </TrendCellContainer>
      </div>
      <ModalComponent {...{ onClose: closeModal }}>
        <ViewApplicationModalContent
          {...{ job: { id: job_id }, modalProps: { closeModal } }}
        />
      </ModalComponent>
    </>
  );
};

export {
  StatusCell,
  DateCell,
  SpecialtyCell,
  ProfessionCell,
  ViewsCell,
  ApplicationsCell,
  datatableUtils,
};
