import { FC, memo, useCallback, useReducer, useState } from 'react';
import { css } from '@emotion/react';
import { router } from '@inertiajs/react';

import Card from '../Card';
import TagLabel from '../TagLabel';
import JobDetail from './JobDetail';
import layout from '@/constants/layout';
import { Job } from '@/types/entities.d';
import BookMarked from '../Job/BookMarked';
import { IconMapPin } from '../Icons/MapPin';
import { IconDollar } from '../Icons/Dollar';
import { appColors } from '@/constants/colors';
import usePageSession from '@/hooks/usePageSession';
import JobListTileHeader from './JobListTileHeader';
import { IconMedicalJob } from '../Icons/MedicalJob';
import { getLabelFromValue } from '@/utils/form-data';
import { favoriteJob, unfavoriteJob } from '@/api/jobs';
import useDropdownsData from '@/hooks/useDropdownsData';
import JobListTilePostedDate from './JobListTilePostedDate';
import JobListTileDescription from './JobListTileDescription';
import { datatableUtils } from '@/constants/datatables/_index';
import { initialState, reducer } from '@/constants/contexts/job-context';
import { Tooltip } from 'react-tooltip';
import ShareButton from '@/components/ShareIcon';

const HOVER_COLOR = appColors.content.tertiary;

const {
  windowSizes: { tablet, mobile },
} = layout;

interface JobListTileProps {
  item: Job;
}

const jobListTileStyles = css`
  display: grid;
  grid-auto-rows: 1fr;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 1fr auto auto auto;
  column-gap: 40px;
  width: 100%;
  flex-grow: 0;
  cursor: pointer;
  @media (max-width: ${tablet}px) {
    column-gap: 0px;
    row-gap: 16px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`;

const jobListTileInfoStyles = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const bookmarkedDesktopStyles = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  gap: 12px;
  color: ${appColors.content.tertiary};
  @media (max-width: ${tablet}px) {
    display: none;
  }
`;

const bookmarkedMobileStyles = css`
  display: none;
  @media (max-width: ${tablet}px) {
    display: flex;
    flex-direction: column;
    color: ${appColors.content.tertiary};
    gap: 12px;
  }
`;
const jobListTileHeaderStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  @media (max-width: ${tablet}px) {
    gap: 0;
  }
`;

const jobListTileDetailsStyles = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  max-width: 85px;
`;

const verticalDividerStyles = css`
  display: flex;
  width: 1px;
  height: 100%;
  background-color: HOVER_COLOR;
  padding: 11px 0px;
  @media (max-width: ${tablet}px) {
    padding: 0px;
    width: 100%;
    height: 1px;
  }
`;
const tooltipContainer = css`
  padding: 16px;
  border-radius: 16px;
  background-color: ${appColors.content.primary};
  text-align: center;
  align-items: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  box-sizing: 10;
  max-width: 180px;
  @media (max-width: ${mobile}px) {
    display: none;
  }
`;

const JobListTile: FC<JobListTileProps> = (props) => {
  const { dropdownsHashes } = useDropdownsData();
  const [item, dispatch] = useReducer(reducer, {
    ...initialState,
    ...props.item,
  });
  const {
    session: { signed_in, current_user },
  } = usePageSession();

  const [isHovered, setIsHovered] = useState(false);

  const isThereCurrentUser = !!current_user;

  const isAdmin = current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;
  //const iconProps = { strokeColor: STROKE_COLOR, fillColor };
  // PROVIDER
  const isAProvider = isThereCurrentUser && current_user.is_type === 'provider';
  // PROVIDER OR NOT LOGGED IN USER
  const isNotARecruiter =
    isThereCurrentUser && current_user.is_type !== 'recruiter';
  const providerId = (isThereCurrentUser && current_user.id) || -1;
  const canFavoritedAndApply = !isSignedIn || isAProvider;

  const onClickTile = useCallback(() => {
    router.get(`/jobs/${props.item.id}`);
  }, [props.item.id]);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const onClickFavorited = useCallback(async () => {
    if (isAProvider) {
      if (item.is_favorite) {
        // UNFAVORITED
        const unfavoritedResponse = await unfavoriteJob({
          job_id: item.id,
        });
        if (!!unfavoritedResponse && unfavoritedResponse.success) {
          dispatch({ type: 'SET_IS_FAVORITED', payload: false });
        }
      } else {
        //FAVORITED
        const favoritedResponse = await favoriteJob({
          job_id: item.id,
          provider_id: providerId,
        });
        if (favoritedResponse && favoritedResponse.saved_job.id) {
          dispatch({ type: 'SET_IS_FAVORITED', payload: true });
        }
      }
    }
  }, [dispatch, providerId, item.is_favorite]);

  const url = `https://www.locums.com/jobs/${props.item.id}`;

  return (
    <Card
      {...{
        css: css({
          ...jobListTileStyles,
        }),
        style: {
          ...(isHovered ? { borderColor: HOVER_COLOR } : {}),
        },
        onMouseOver: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        onClick: onClickTile,
      }}
    >
      <div {...{ css: jobListTileInfoStyles }}>
        {((signed_in && props.item.has_applied) ||
          localStorage.getItem(`${props.item.id}`) === 'true') && (
          <TagLabel
            {...{
              text:
                signed_in && props.item.applied_date
                  ? `Applied on ${props.item.applied_date}`
                  : 'Applied',
              variant: 4,
              size: 'lg',
            }}
          />
        )}
        <div {...{ css: jobListTileHeaderStyles }}>
          <JobListTileHeader
            {...{
              title: item.title || '',
              style: {
                ...(isHovered ? { color: HOVER_COLOR } : {}),
              },
            }}
          />
          {canFavoritedAndApply && (
            <div {...{ css: bookmarkedMobileStyles }}>
              <BookMarked
                {...{
                  id: item.id,
                  isActive: item.is_favorite,
                  action: onClickFavorited,
                }}
              />
              <ShareButton {...{ linkedinUrl: url, emailUrl: url }} />
            </div>
          )}
        </div>
        <JobListTilePostedDate {...{ postedDate: item.posted_at }} />
        <JobListTileDescription
          {...{
            description: item.description,
          }}
        />
      </div>
      {/*  */}
      <div {...{ css: verticalDividerStyles }} />
      {/*  */}
      <div {...{ css: jobListTileDetailsStyles }}>
        <JobDetail
          {...{
            icon: IconMedicalJob,
            detail: getLabelFromValue(
              item.profession,
              dropdownsHashes.profession
            ),
            // detail: dropdownsHashes.profession?.[item.profession]?.label,
          }}
        />
        <JobDetail
          {...{
            icon: IconMapPin,
            detail: item.state,
          }}
        />

        <div data-tooltip-id="my-tooltip">
          <JobDetail
            {...{
              icon: IconDollar,
              detail:
                !isSignedIn ||
                (current_user?.is_type === 'recruiter' &&
                  item?.recruiter_id !== current_user?.id)
                  ? 'N/A'
                  : datatableUtils.getFormattedHourlyRate(
                      item?.min_hourly_rate
                    ),
              isBlurred:
                !isSignedIn ||
                (current_user?.is_type === 'recruiter' &&
                  item?.recruiter_id !== current_user?.id), //not signed in, or is not the recruiter who posted it
            }}
          />
          {!isSignedIn ||
          (current_user?.is_type === 'recruiter' &&
            item?.recruiter_id !== current_user?.id) ? (
            <Tooltip
              {...{
                id: 'my-tooltip',
                noArrow: true,
                css: tooltipContainer,
              }}
            >
              <p>Sign In to see Rates!</p>
            </Tooltip>
          ) : null}
        </div>
      </div>
      {/*  */}

      {canFavoritedAndApply && (
        <div {...{ css: bookmarkedDesktopStyles }}>
          <BookMarked
            {...{
              id: item.id,
              isActive: item.is_favorite,
              action: onClickFavorited,
            }}
          />
          <ShareButton linkedinUrl={url} emailUrl={url} />
        </div>
      )}
    </Card>
  );
};

export default memo(JobListTile);