import { useCallback, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { FormProvider, useForm } from 'react-hook-form';
import { router, Head } from '@inertiajs/react';

import {
  contentStyle,
  footerStyle,
  topBarStyle,
} from '@/layouts/TopContentFooterLayout';
import {
  FilterMenuButtons,
  FilterMenuHeader,
  FiltersMenuLayout,
} from '@/components/Filter/FiltersMenu';
import layout from '@/constants/layout';
import { NavBarProps } from '@/types/layout';
import selectsData from '@/constants/selects';
import CloseIcon from '@/components/CloseIcon';
import AuthModal from '@/components/AuthModal';
import { appColors } from '@/constants/colors';
import NavBar from '@/components/Layout/NavBar';
import Footer from '@/components/Layout/Footer';
import Bottomsheet from '@/components/Bottomsheet';
import usePageSession from '@/hooks/usePageSession';
import { MultiValueText } from '@/components/Forms';
import Heading from '@/components/Typography/Heading';
import { HomeSearchParams } from '@/types/api/params';
import { IconSearch } from '@/components/Icons/Search';
import useDropdownsData from '@/hooks/useDropdownsData';
import { getParamValueFromForm } from '@/utils/form-data';
import HomeFilterBar from '@/components/Home/HomeFilterBar';
import { fieldsDefaultProps } from '@/constants/form-fields';
import CurvedBG from '../../../app/assets/images/curvedbg.svg';
import HomeFilterForm from '@/components/Forms/Home/HomeFilterForm';
import MultiSelectOption from '@/components/InputDropdown/MultiSelectOption';
import SearchLinkDisplayer from '@/components/Home/SearchLinkDisplayer';
import CardImage from '@/components/Card/CardImage';
import Collage from '../../assets/images/1000-page.jpg';
import Tip from '../../assets/images/tip.png';
import City from '../../assets/images/city.png';
import Typewriter from 'typewriter-effect';
import Dentist from '../../assets/images/dentist-home.jpg';
import { Divider } from '@mui/material';
import styled from '@emotion/styled';

const SEARCH_URL = '/jobs';
const TEXT_COLOR = appColors.content.white;

const {
  windowSizes: { desktop, mobile, tablet, desktopHD },
  components: { iconStyles },
  mediaQuery,
} = layout;

const heroImgStyle = css`
  background-image: ${`url(${CurvedBG})`};
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-color: ${appColors.background.secondary}; //#30302f
`;

const headerContainerStyle = css`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: ${desktop}px) {
    padding: 0px 15vw;
  }
  @media (max-width: ${mobile}px) {
    padding: 0 16px;
  }
`;

const pageContentStyle = css`
  width: 100%;
  background-color: ${appColors.background.secondary};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0px 0px 30px 90px;
  @media (max-width: ${desktop}px) {
    padding: 0px;
  }
  @media (max-width: ${mobile}px) {
    flex-direction: column;
    padding: 0 3em 3em 3em;
    gap: 40px;
  }
`;
const header = css`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0 0 0 5.5em;
  align-self: flex-start;
  display: inline-flex;
  @media (max-width: ${desktop}px) {
    margin: 0 0 0 0.5em;
  }

  @media (max-width: ${mobile}px) {
    margin: auto;
  }
`;
const headerContainer = css`
  background-color: ${appColors.background.secondary};
  padding: 2em 0;
  @media (max-width: ${mobile}px) {
    display: flex;
    justify-content: center;
  }
`;

const menuContainer = css`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Typer = css`
  padding-left: 0.3em;
  padding-right: 0.3em;
`;

const DividerStyle = styled(Divider)`
  @media (max-width: ${mobile}px) {
    width: 80%;
    //border-color: ${appColors.content.tertiary};
    margin: auto;
    border-width: 1px 0px thin;
  }
`;

function Type(props: any) {
  return (
    <Typewriter
      options={{
        ...props,
        strings: ['practice', 'recruit'],
        autoStart: true,
        loop: true,
        deleteSpeed: 60,
      }}
    />
  );
}

const initialNav = [
  { label: 'News', url: '/news' },
  { label: 'Job Board', url: '/jobs' },
  { label: 'Contact Us', url: '/contact' },
];

type FiltersHomeFormValues = {
  professions: '';
  state_in: '';
  specialties: '';
};

const initialFilterValues: FiltersHomeFormValues = {
  professions: '',
  state_in: '',
  specialties: '',
};

const FilterBar = HomeFilterBar<FiltersHomeFormValues>;
const Form = HomeFilterForm<FiltersHomeFormValues>;

export default function HomePage() {
  const [isFilterMenuActive, setIsFilterMenuActive] = useState(false);
  const {
    session: { signed_in, current_user },
  } = usePageSession();

  const isAdmin = current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;

  const methods = useForm<FiltersHomeFormValues>({
    defaultValues: {
      ...initialFilterValues,
    },
    shouldUnregister: false,
  });

  const { dropdownOptions } = useDropdownsData();

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isDirty },
    watch,
  } = methods;

  const navProps: NavBarProps = useMemo(() => {
    if (isSignedIn) {
      return {
        navLinks: [
          ...initialNav,
          {
            label: 'Dashboard',
            url:
              current_user?.is_type === 'recruiter'
                ? '/recruiters/jobs'
                : '/providers/applied_jobs?per=5&page=1',
          },
        ],
        buttons: [
          { children: 'Log in', variant: '1' },
          { children: 'Sign Up', variant: '2' },
        ],
      };
    }
    return {
      navLinks: initialNav,
      buttons: [
        { children: 'Log in', variant: '1' },
        { children: 'Sign Up', variant: '2' },
      ],
    };
  }, [isSignedIn]);

  const triggerSearch = useCallback(
    async ({ form }: { form: FiltersHomeFormValues }) => {
      const hasProfession = !!form?.professions;
      const hasState = !!form?.state_in && Array.isArray(form?.state_in);
      const hasSpecialty = !!form?.specialties;

      const params: HomeSearchParams = {
        q: {
          ...getParamValueFromForm('state_in', form?.state_in),
        },
        ...getParamValueFromForm('professions', form?.professions),
        ...getParamValueFromForm('specialties', form?.specialties),
      };

      const hasAtLeastOneParam = hasProfession || hasState || hasSpecialty;
      if (hasAtLeastOneParam) {
        router.get(SEARCH_URL, { ...params }, { data: {} });
      }
    },
    []
  );

  const handleFilterMenu = useCallback(() => {
    setIsFilterMenuActive(!isFilterMenuActive);
    return;
  }, [isFilterMenuActive]);

  const submitForm = useCallback(
    async (form: FiltersHomeFormValues) => {
      triggerSearch({
        form,
      });
    },
    [triggerSearch]
  );

  const onClickResetMobile = useCallback(() => {
    reset({
      ...initialFilterValues,
    });
    handleSubmit(submitForm)();
    return;
  }, []);

  const styles = {
    container: (base: any) => ({
      ...base,
      '@media (min-width: 768px)': {
        width: 290,
      },
    }),
    menu: (base: any) => ({
      ...base,
      width: 355,
    }),
    valueContainer: (base: any) => ({
      ...base,
      left: '1em',
    }),
  };

  const cardConent = [
    {
      link: '/news/dentist-shortages',
      imageUrl: Dentist,
      imageAlt: 'Dentist with a patient',
      publishedDate: 'Aug 15, 2024 ',
      mainTitle: 'Locum tenens ease dentist shortages to advance patient care',
      bordered: true,
    },
    {
      link: '/news/the-state-of-locums-a-recruiter-prospective',
      imageUrl: Tip,
      imageAlt: 'A female physician',
      mainTitle: 'We hit 1,000 followers in record time, thanks to you',
      publishedDate: 'Jul 09, 2024',
      bordered: true,
    },
    {
      link: '/news/locum-tenens-taxes',
      imageUrl: City,
      imageAlt: 'Downtown City',
      mainTitle: 'What every locum tenens provider needs to know about taxes',
      publishedDate: 'May 18, 2024',
      bordered: true,
    },
   
  ];
  return (
    <>
      <Head>
        <title>Locums Job Board</title>
        <meta
          name="description"
          content="Explore premier locum tenens opportunities at Locums.com! Connecting doctors with top placements and empowering recruiters. Join our thriving community for personalized matches, flexibility, and excellence in healthcare staffing. Elevate your career or find top-tier talent with Locums.com – Empowering you to practice your way!"
        />
      </Head>
      <FormProvider {...methods}>
        <div {...{ css: heroImgStyle }}>
          <div
            {...{
              css: topBarStyle,
              style: {
                background: 'transparent',
                borderBottom: 'none',
                color: 'white',
              },
            }}
          >
            <NavBar {...{ ...navProps, logoVariant: 'blue-white' }} />
          </div>

          <div
            {...{
              css: css`
                ${contentStyle}
                background-color: transparent;
                flex-direction: column;
                padding-bottom: 80px;
                @media (max-width: ${tablet}px) {
                  padding: 90px 0%;
                }
                @media (max-width: ${mobile}px) {
                }
              `,
            }}
          >
            <div {...{ css: headerContainerStyle }}>
              <label
                {...{
                  css: css`
                    display: flex;
                    text-align: center;
                    font-family: Hepta Slab;
                    font-size: 50px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 140%;
                    letter-spacing: -1px;
                    @media (max-width: ${tablet}px) {
                      font-weight: 600;
                      font-size: 30px;
                      flex-direction: column;
                    }
                  `,
                  children: (
                    <>
                      Empowering you to{' '}
                      <div {...{ css: Typer }}>
                        <Type />
                      </div>{' '}
                      your way
                    </>
                  ),
                  style: { color: TEXT_COLOR },
                }}
              />
              {/* 
                Remove subtitle for now
                <Paragraph
                  {...{
                    style: { color: TEXT_COLOR },
                    variant: 'lg',
                    children: 'Here is a subtitle to support the main heading',
                  }}
                /> */}
            </div>
            <div
              {...{
                css: css`
                  display: flex;
                  width: 100%;
                  padding: 0px 30vw;
                  @media (max-width: ${desktopHD}px) {
                    padding: 0px 27.5vw;
                  }
                  @media (max-width: ${desktop}px) {
                    padding: 0px 7%;
                  }
                  @media (max-width: ${mobile}px) {
                    padding: 0 16px;
                  }
                `,
              }}
            >
              <FilterBar
                {...{
                  isSearchDisabled: false,
                  filters: [
                    {
                      dropDownProps: {
                        name: 'professions',
                        placeholder: fieldsDefaultProps['profession_eq'].label,
                        options: dropdownOptions.profession,
                        isClearable: true,
                      },
                    },
                    {
                      dropDownProps: {
                        name: 'state_in',
                        placeholder: fieldsDefaultProps['state_in'].label,
                        options: selectsData['state'],
                        isMulti: true,
                        isClearable: true,
                        isSearchable: true,
                        components: {
                          MultiValue: (rest) => (
                            <MultiValueText
                              {...{
                                ...rest,
                                shownValue: 'value',
                                truncateNumber: 2,
                              }}
                            />
                          ),
                          Option: (rest) => (
                            <MultiSelectOption
                              {...{ ...rest, shownValue: 'value' }}
                            />
                          ),
                        },
                      },
                    },
                    {
                      dropDownProps: {
                        name: 'specialties',
                        placeholder: fieldsDefaultProps['specialty_eq'].label,
                        options: dropdownOptions.specialty,
                        isMulti: true,
                        isClearable: true,
                        styles: styles,
                        isSearchable: true,
                        components: {
                          MultiValue: (rest) => (
                            <MultiValueText
                              {...{
                                ...rest,
                                shownValue: 'label',
                                isFirstOptionTruncated: true,
                                truncateNumber: 10,
                              }}
                            />
                          ),
                          Option: (rest) => (
                            <MultiSelectOption
                              {...{ ...rest, shownValue: 'label' }}
                            />
                          ),
                        },
                      },
                    },
                  ],
                  handleFilterMenu,
                  submitForm,
                }}
              />
            </div>
            <div {...{ css: menuContainer }}>
              <Heading
                {...{
                  style: {
                    color: '#fff',
                    alignSelf: 'center',
                    marginBottom: '1em',
                  },
                  variant: 'h2',
                  children: 'Trending Searches',
                }}
              />
              <SearchLinkDisplayer />
            </div>
          </div>
        </div>
        <div {...{ css: headerContainer }}>
          {' '}
          <h1 {...{ css: header }}>Latest Posts</h1>
        </div>
        <div {...{ css: pageContentStyle }}>
          {cardConent.map((card) => (
            <>
              <CardImage
                {...{
                  onClick: () => router.get(card.link),
                  imageUrl: card.imageUrl,
                  imageAlt: card.imageAlt,
                  publishedDate: card.publishedDate,
                  mainTitle: card.mainTitle,
                  bordered: card.bordered,
                }}
              />
              <DividerStyle />
            </>
          ))}
        </div>
        <div {...{ css: footerStyle }}>
          <Footer {...{ ...navProps, logoVariant: 'blue-white' }} />
        </div>
        <div
          {...{
            css: css`
              display: none;
              @media (max-width: ${tablet}px) {
                display: ${isFilterMenuActive ? 'flex' : 'none'};
              }
            `,
          }}
        >
          <Bottomsheet
            {...{
              isOpen: isFilterMenuActive,
              onClose: handleFilterMenu,
              isLoading: isSubmitting,
            }}
          >
            <FiltersMenuLayout
              {...{
                cardProps: { hasBorder: false },
                header: (
                  <>
                    <FilterMenuHeader {...{ header: 'Search' }} />
                    <CloseIcon {...{ onClick: handleFilterMenu }} />
                  </>
                ),
                content: (
                  <>
                    <Form {...{}} />
                  </>
                ),
                buttons: (
                  <FilterMenuButtons
                    {...{
                      resetButtonProps: {
                        onClick: onClickResetMobile,
                        // disabled: !isDirty,
                      },
                      applyButtonProps: {
                        children: (
                          <div
                            {...{
                              css: css`
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: center;
                              `,
                            }}
                          >
                            Search
                            <div
                              {...{
                                css: css`
                                  ${iconStyles}
                                  margin-left: 4px;
                                `,
                              }}
                            >
                              <IconSearch {...{ iconColor: 'white' }} />
                            </div>
                          </div>
                        ),
                        onClick: () => {
                          handleFilterMenu();
                          handleSubmit(submitForm)();
                        },
                        disabled: !isDirty,
                      },
                    }}
                  />
                ),
              }}
            />
          </Bottomsheet>
        </div>
        <AuthModal />
      </FormProvider>
    </>
  );
}
