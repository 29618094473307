import { css } from '@emotion/react';
import { FieldValues, useFormContext } from 'react-hook-form';

import Card from '../Card';
import layout from '@/constants/layout';
import FilterDropdowns from './FilterDropdowns';
import SearchIconButton from './SearchIconButton';
import { FilterButtonProps } from './FilterButton';
import MoreLessFilterButton from './MoreLessFilterButton';

const HEIGHT = 62;

const {
  windowSizes: { tablet, mobile },
} = layout;

const minTablet = tablet + 1;
const minMobile = mobile + 1;

export const FILTER_BAR_COMMONS = {
  HEIGHT,
};

interface FilterProps<T> {
  filters: FilterButtonProps[];
  isFilterMenuActive: boolean;
  isSearchDisabled: boolean;
  handleFilterMenu: () => void;
  activeFilters: number;
  submitForm: (form: T) => void;
  hasMoreFilterButton: boolean;
}

const filterBarContainerStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 24px;
  width: 100%;
  @media (max-width: ${tablet}px) {
    gap: 0px;
  }
`;

const filterBarCardStyles = css`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: ${HEIGHT}px;
  gap: 12px;
  padding: 8px 8px;
  border-radius: 100px;
  width: 100%;
  @media (max-width: ${tablet}px) {
    padding: 8px 8px 8px 24px;
    gap: 16px;
  }
`;

const Filter = <T extends FieldValues>({
  filters = [],
  activeFilters,
  isFilterMenuActive,
  isSearchDisabled,
  handleFilterMenu,
  submitForm,
}: FilterProps<T>) => {
  const Dropdowns = FilterDropdowns<T>;

  const { handleSubmit } = useFormContext<T>();

  return (
    <div {...{ css: filterBarContainerStyles }}>
      <Card {...{ css: filterBarCardStyles, hasShadow: true }}>
        <div
          {...{
            css: css`
              width: 100%;
              @media (max-width: ${mobile}px) {
                display: none;
              }
            `,
          }}
        >
          <Dropdowns {...{ filters }} />
        </div>
        <div
          {...{
            onClick: handleFilterMenu,
            css: css`
              width: 100%;
              cursor: pointer;
              @media (min-width: ${minMobile}px) {
                display: none;
              }
            `,
          }}
        >
          <Dropdowns {...{ filters }} />
        </div>
        <div
          {...{
            css: css`
              display: flex;
              @media (max-width: ${minTablet}px) {
                display: none;
              }
            `,
          }}
        >
          <SearchIconButton
            {...{
              onClick: handleSubmit(submitForm),
              isDisabled: isSearchDisabled,
            }}
          />
        </div>
        <div
          {...{
            css: css`
              display: flex;
              @media (min-width: ${minTablet}px) {
                display: none;
              }
            `,
          }}
        >
          <SearchIconButton {...{ onClick: handleFilterMenu }} />
        </div>
      </Card>
      <div
        {...{
          css: css`
            @media (max-width: ${tablet}px) {
              display: none;
            }
          `,
        }}
      >
        <MoreLessFilterButton
          {...{
            isActive: isFilterMenuActive,
            onClick: handleFilterMenu,
            activeFilters,
          }}
        />
      </div>
    </div>
  );
};

export default Filter;
