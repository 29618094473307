import { ColumnDef } from '@tanstack/react-table';

import {
  ApplicationsCell,
  DateCell,
  ProfessionCell,
  SpecialtyCell,
  StatusCell,
  ViewsCell,
} from './_index';
import { Job } from '@/types/entities.d';
import Checkbox from '@/components/Checkbox';
import ColumnHeader from '@/components/Table/ColumnHeader';
import { ApplicationsCount, ViewsCount } from '@/types/types';
import CellRendererText from '@/components/Table/CellRendererText';

// // //

// //

const columns: ColumnDef<Job>[] = [
  {
    id: 'select',
    accessorKey: 'select',
    enableSorting: false,
    header: ({ table }) => {
      return (
        <div>
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      );
    },
    cell: ({ row }) => {
      return (
        <div>
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      );
    },
  },
  {
    header: () => <ColumnHeader {...{ label: 'Locums ID' }} />,
    accessorKey: 'id',
    cell: (info) => <CellRendererText {...{ text: info.getValue() || '' }} />,
  },
  {
    header: () => <ColumnHeader {...{ label: 'Post Date' }} />,
    accessorKey: 'posted_at',
    cell: (info) => <DateCell {...{ value: info.getValue() }} />,
  },
  {
    header: () => <ColumnHeader {...{ label: 'Facility Name' }} />,
    accessorKey: 'facility_name',
    cell: (info) => <CellRendererText {...{ text: info.getValue() || '' }} />,
  },
  {
    header: () => <ColumnHeader {...{ label: 'City' }} />,
    accessorKey: 'city',
    cell: (info) => <CellRendererText {...{ text: info.getValue() || '' }} />,
  },
  {
    header: () => <ColumnHeader {...{ label: 'State' }} />,
    accessorKey: 'state',
    cell: (info) => <CellRendererText {...{ text: info.getValue() || '' }} />,
  },
  {
    header: () => <ColumnHeader {...{ label: 'Specialty' }} />,
    accessorKey: 'specialty',
    cell: (info) => <SpecialtyCell {...{ value: info.getValue() }} />,
  },
  {
    header: () => <ColumnHeader {...{ label: 'Profession' }} />,
    accessorKey: 'profession',
    cell: (info) => <ProfessionCell {...{ value: info.getValue() }} />,
  },
  {
    header: () => <ColumnHeader {...{ label: 'Status' }} />,
    accessorKey: 'is_active',
    cell: (info) => <StatusCell {...{ value: info.getValue() }} />,
  },
  {
    header: () => <ColumnHeader {...{ label: 'Views' }} />,
    id: 'view_count',
    accessorKey: 'view_counts',
    cell: (info) => {
      const value = (info.getValue() || {}) as ViewsCount;

      return (
        <ViewsCell
          {...{
            ...value,
          }}
        />
      );
    },
  },
  {
    header: () => <ColumnHeader {...{ label: 'Applications' }} />,
    id: 'application_count',
    accessorKey: 'application_counts',
    cell: (info) => {
      const value = (info.getValue() || {}) as ApplicationsCount;

      return (
        <ApplicationsCell
          {...{
            ...value,
            job_id: value?.job_id,
          }}
        />
      );
    },
  },
];

const recruiterCustomDatatableConfig = {
  columns,
};

export { recruiterCustomDatatableConfig };
