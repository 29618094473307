import {
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  forwardRef,
} from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { IconCheck } from '../Icons/Check';
import { appColors } from '@/constants/colors';
import { paragraphDesktop } from '@/constants/typography';

const SIZE = 24;
const BORDER_RADIUS = 4;
const BORDER_WIDTH = 1;

const CHECKED_COLOR = appColors.content.tertiary;
const DISABLED_COLOR = appColors.content.disabled;

type Variant = 'default' | 'select';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  readonly label?: string;
  readonly variant?: Variant;
  readonly isFocused?: boolean;
}

const StyledCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export const StyledLabel = styled.label({
  ...paragraphDesktop.md,
  color: appColors.content.secondary,
});

const disabledStyles = css`
  background-color: ${DISABLED_COLOR};
  border-color: ${DISABLED_COLOR};
  cursor: not-allowed;
`;

const StyledCheckbox = styled.input`
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  width: ${SIZE}px;
  height: ${SIZE}px;
  background-color: #fff;
  border-radius: ${BORDER_RADIUS}px;
  border: ${BORDER_WIDTH}px solid ${appColors.border.quaternary};
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin: 0;

  &:hover {
    border-color: ${CHECKED_COLOR};
  }

  &:checked {
    background-color: ${CHECKED_COLOR};
    border-color: ${CHECKED_COLOR};
    &:disabled {
      ${disabledStyles}
    }
  }

  &:disabled {
    ${disabledStyles}
    background-color: transparent
  }
`;

const Checkbox: ForwardRefRenderFunction<HTMLInputElement, CheckboxProps> = (
  { label, variant = 'default', ...props },
  ref
) => {
  return (
    <StyledCheckboxWrapper>
      <div
        {...{
          css: css`
            display: flex;
            position: relative;
          `,
        }}
      >
        <StyledCheckbox
          {...{
            ...props,

            type: 'checkbox',
            style: {
              ...(props?.isFocused && !props.checked
                ? {
                    borderColor: appColors.content.primary,
                  }
                : {}),
            },
            ref,
          }}
        />
        {(props.checked || props.value) && (
          <div
            {...{
              css: css`
                position: absolute;
                pointer-events: none;
                width: ${SIZE}px;
                height: ${SIZE}px;
              `,
            }}
          >
            <IconCheck {...{ iconColor: 'white' }} />
          </div>
        )}
      </div>
      {!!label && (
        <StyledLabel
          {...{
            children: label,
            style: {
              ...(variant === 'select' ? { cursor: 'pointer' } : {}),
              ...(props?.isFocused
                ? {
                    color: appColors.content.primary,
                  }
                : {}),
              ...(props?.checked
                ? {
                    color: appColors.content.primary,
                  }
                : {}),
              ...(props?.disabled
                ? {
                    color: appColors.content.disabled,
                  }
                : {}),
            },
          }}
        />
      )}
    </StyledCheckboxWrapper>
  );
};

export default forwardRef(Checkbox);
