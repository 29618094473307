import { ComponentProps, useCallback, useEffect, useReducer } from 'react';
import styled from '@emotion/styled';
import { router, usePage, useForm as useInertiaForm } from '@inertiajs/react';
import { css } from '@emotion/react';

import {
  HEADER_TITLE,
  providerDashboardContainer,
  providerDashboardContentContainer,
  providerDashboardJobContainer,
  providerDashboardMenuContainer,
} from '../_index';
import {
  initialState,
  reducer,
} from '@/constants/contexts/providers-dashboard';
import SearchBar from '../SearchBar';
import NavButton from '../NavButton';
import List from '@/components/List';
import PageHeader from '../PageHeader';
import layout from '@/constants/layout';
import { Job } from '@/types/entities.d';
import JobSelected from '../JobSelected';
import Button from '@/components/Button';
import { unfavoriteJob } from '@/api/jobs';
import { getMetaToken } from '@/utils/meta-token';
import { Controller, useForm } from 'react-hook-form';
import { ProviderSavedJobsProps } from '@/types/page';
import { useUrlSearchParams } from 'use-url-search-params';
import { transformQueryParams } from '@/utils/query-params';
import { ProvidersDashboardFormValues } from '@/types/forms';
import NoResultsFound from '@/components/List/NoResultsFound';
import JobListTileSmall from '@/components/ClinicianDashboard/JobListTileSmall';

const URL = `/providers/saved_jobs`;

const {
  windowSizes: { tablet },
} = layout;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

export const NavHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const NavHeaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 4px;
`;

const navButtons: ComponentProps<typeof NavButton>[] = [
  {
    children: 'Applied Jobs',
    onClick: () => {
      router.get(`/providers/applied_jobs?per=5&page=1`);
    },
  },
  {
    children: 'Saved Jobs',
    onClick: () => {
      // router.get(`/providers/saved_jobs`);
    },
  },
  {
    children: 'My Details',
    onClick: () => {
      router.get(
        `/providers/details`,
        {},
        {
          preserveState: false,
          preserveScroll: false,
        }
      );
    },
  },
];

const JobList = List<Job>;

function ProvidersSaved() {
  const { get, transform } = useInertiaForm();

  const page = usePage<ProviderSavedJobsProps>();
  const { props } = page;

  const [params, setParams] = useUrlSearchParams({});
  const urlParams = params as unknown as ProvidersDashboardFormValues;
  const parsedParams =
    transformQueryParams<ProvidersDashboardFormValues>(urlParams);

  const initialFilterValues: ProvidersDashboardFormValues = {
    q: {
      title_cont: parsedParams?.q?.title_cont || '',
    },
    per: parsedParams?.per || 5,
    page: parsedParams?.page || 1,
  };

  // FORM STATE
  const methods = useForm<ProvidersDashboardFormValues>({
    defaultValues: {
      ...initialFilterValues,
    },
    shouldUnregister: false,
  });

  const { control, handleSubmit, setValue } = methods;
  const { collection, pagination } = props;

  // LOCAL STATE
  const [{ modal, savedJobs }, localDispatch] = useReducer(reducer, {
    ...initialState,
    savedJobs: collection,
  });
  const { selectedJobId } = modal;
  const selectedJob =
    savedJobs.find((job) => job.id === selectedJobId) || savedJobs[0];
  const isSavedJobsEmpty = savedJobs.length === 0;

  const triggerSearch = useCallback(
    async (form: ProvidersDashboardFormValues) => {
      const hasSearchValue = !!form?.q?.title_cont;

      const params = {
        q: {
          ...(hasSearchValue ? { title_cont: form.q.title_cont } : {}),
        },
        per: form.per,
        page: form.page,
      };

      const metaTokenHeader = await getMetaToken();
      if (!!metaTokenHeader) {
        get(URL, {
          preserveState: true,
          data: transform(() => ({
            ...params,
          })),
          headers: {
            ...metaTokenHeader,
          },
        });
      }
    },
    []
  );

  const submitSearch = useCallback(async () => {
    changePage(1);
  }, [triggerSearch]);

  const onOpenTileModal = useCallback(
    (job: Job) => {
      localDispatch({ type: 'OPEN_TILE_MODAL', payload: job });
    },
    [localDispatch]
  );

  const onRowClicked = useCallback(
    (job: Job) => {
      onOpenTileModal(job);
    },
    [onOpenTileModal]
  );

  const onClickBack = useCallback(() => {
    localDispatch({ type: 'CLOSE_TILE_MODAL', payload: null });
  }, [localDispatch]);

  const changePage = useCallback(async (page: number) => {
    setValue('page', page, {
      shouldDirty: false,
      shouldTouch: false,
      shouldValidate: false,
    });
    handleSubmit(triggerSearch)();
  }, []);

  const onClickFavorited = useCallback(
    async (job: Job) => {
      if (job?.is_favorite) {
        // UNFAVORITED
        const unfavoritedResponse = await unfavoriteJob({
          job_id: job.id,
        });
        if (!!unfavoritedResponse && unfavoritedResponse.success) {
          localDispatch({ type: 'UNFAVORITE_SAVED_JOB', payload: job.id });
        }
      }
    },
    [localDispatch]
  );

  const setHasApplied = useCallback(() => {
    localDispatch({ type: 'SET_HAS_APPLIED', payload: selectedJob?.id });
  }, [selectedJob?.id]);

  useEffect(() => {
    if (collection.length > 0 && collection[0]?.id !== savedJobs[0]?.id) {
      localDispatch({ type: 'SET_SAVED', payload: collection });
    }
  }, [collection]);

  return (
    <div {...{ css: providerDashboardContainer }}>
      <HeaderContainer>
        <PageHeader {...{ variant: 'h1Small', children: HEADER_TITLE }} />
        <NavHeaderContainer>
          <NavHeaderButtonsContainer>
            {navButtons.map((navButton, index) => {
              const isActive = index === 1;
              return <NavButton {...{ ...navButton, isActive, key: index }} />;
            })}
          </NavHeaderButtonsContainer>
        </NavHeaderContainer>
      </HeaderContainer>
      {isSavedJobsEmpty ? (
        <div
          {...{
            css: css`
              display: flex;
              width: 100%;
            `,
          }}
        >
          <NoResultsFound
            {...{
              header: `You haven't saved any jobs yet`,
              node: () => (
                <div>
                  <Button
                    children="Start Searching for Jobs"
                    onClick={() => router.get(`/jobs`)}
                    style={{}}
                  />
                </div>
              ),
            }}
          />
        </div>
      ) : (
        <div {...{ css: providerDashboardContentContainer }}>
          <div
            {...{
              css: css`
                ${providerDashboardMenuContainer}
                @media (max-width: ${tablet}px) {
                  display: ${!!selectedJobId ? 'none' : 'flex'};
                }
              `,
            }}
          >
            <div>
              <Controller
                {...{
                  name: 'q.title_cont',
                  control,
                  rules: {},
                  render: ({ field }) => {
                    return (
                      <SearchBar
                        {...{
                          ...field,
                          searchIconAction: handleSubmit(submitSearch),
                          placeholder: 'Search saved jobs',
                          containerStyles: { width: '100%' },
                        }}
                      />
                    );
                  },
                }}
              />
            </div>
            <JobList
              {...{
                data: savedJobs,
                pagination: pagination,
                changePage,
                RenderedItem: ({ item }) => (
                  <JobListTileSmall
                    {...{
                      item,
                      selectTile: () => onRowClicked(item),
                      onClickFavorited: () => onClickFavorited(item),
                      selectedItemId: selectedJobId,
                    }}
                  />
                ),
              }}
            />
          </div>
          <div
            {...{
              css: css`
                ${providerDashboardJobContainer}
                @media (max-width: ${tablet}px) {
                  display: ${!!selectedJobId ? 'flex' : 'none'};
                }
              `,
            }}
          >
            {selectedJob && (
              <JobSelected
                {...{
                  item: selectedJob,
                  onClickFavorited,
                  setHasApplied,
                  type: 'saved',
                  selectedId: selectedJobId,
                  onClickBack,
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ProvidersSaved;
