import React, { ReactNode, memo, useEffect } from 'react';
import { css } from '@emotion/react';
import { useTransition, animated, config } from '@react-spring/web';

const OVERLAY_COLOR = 'rgba(0, 0, 0, 0.5)';

const bottomSheetStyles = css`
  position: fixed;
  z-index: 9995;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
`;

const overlayStyles = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${OVERLAY_COLOR};
  z-index: 9994;
`;

const contentContainerStyles = css`
  /* height: fit-content; */
  z-index: 9995;
  max-height: 100vh;
  overflow-y: auto;
`;

export type BottomSheetProps = {
  onClose: () => void;
  children: ReactNode;
  isLoading?: boolean;
  isAnimated?: boolean;
  isOpen: boolean;
};

const BottomSheet: React.FC<BottomSheetProps> = ({
  onClose,
  children,
  isLoading,
  isAnimated,
  isOpen,
}) => {
  const transitions = useTransition(true, {
    from: { opacity: 0, transform: 'translateY(100%)' },
    enter: { opacity: 1, transform: 'translateY(0%)' },
    leave: { opacity: 0, transform: 'translateY(100%)' },
    config: { ...config.default, duration: 250 },
  });

  useEffect(() => {
    // Add overflow: hidden to html and body when the bottom sheet is open
    if (isOpen) {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    } else {
      // Remove overflow: hidden when the bottom sheet is closed
      document.documentElement.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    }

    return () => {
      // Cleanup: Restore the original overflow settings
      document.documentElement.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    };
  }, [transitions]);

  return transitions((styles, item) => {
    if (item)
      return (
        <>
          <animated.div
            {...{
              onClick: onClose,
              css: css`
                ${overlayStyles}
                pointer-events: ${isLoading ? 'none' : 'auto'};
              `,
              ...(isAnimated ? { style: styles } : {}),
            }}
          />
          <animated.div
            {...{
              css: bottomSheetStyles,
              ...(isAnimated ? { style: styles } : {}),
            }}
          >
            <div {...{ css: contentContainerStyles, children }} />
          </animated.div>
        </>
      );
  });
};

export default memo(BottomSheet);
