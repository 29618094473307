import styled from '@emotion/styled';
import { FC, memo, useState } from 'react';
import { EmailShareButton, LinkedinShareButton } from 'react-share';
import { ShareIcon } from '../Icons/ShareIcon';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { IconButton, MenuItem, Menu } from '@mui/material';
import { appColors } from '@/constants/colors';
import Card from '../Card';
import { css } from '@emotion/react';

interface MenuProps {
  linkedinUrl: string;
  emailUrl: string;
  hasCardWrap?: boolean;
}

const ShareButton: FC<MenuProps> = ({ linkedinUrl, emailUrl, hasCardWrap }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  const renderShareMenu = () => (
    <Menu                                                                                                                                                                                                                                          
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem onClick={handleClose}>
        <EmailShareButton
          url=''
          subject="I'm sharing a Locums.com job opportunity with you"
         body={`I thought you might be interested in this job listing.\nLearn more and apply:  ${emailUrl}`}
       
       >
          <MailOutlineIcon sx={iconStyles} />
        </EmailShareButton>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <LinkedinShareButton
          url={linkedinUrl}
          title="I wanted to share this exciting locum tenens job opportunity. Please apply, tag someone else who might be interested, or send this to a friend."
          summary=""
          source=""
        >
          <LinkedInIcon sx={iconStyles} />
        </LinkedinShareButton>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      {hasCardWrap ? (
        <Card
          style={{
            padding: 12,
            paddingTop: 12,
            paddingBottom: 12,
            borderRadius: 8,
            cursor: 'pointer',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <div style={iconStyles}>
            <ShareIcon />
          </div>
          {renderShareMenu()}
        </Card>
      ) : (
        <>
          <StyledButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <ShareIcon />
          </StyledButton>
          {renderShareMenu()}
        </>
      )}
    </>
  );
};

export default memo(ShareButton);

const StyledButton = styled(IconButton)`
  &.MuiIconButton-root {
    margin: 0;
    padding: 0;
  }
  &:hover {
    background-color: #fff;
  }
`;

const iconStyles = {
  color: appColors.content.tertiary,
};

